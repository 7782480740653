import React from 'react'
import './Contacto.css'
import useClientes from '../../hooks/useClientes'
import { Alert } from '../../utils/alerts'

export default function Contacto() {
    const { addClienteService } = useClientes()

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const onSubmitCliente = async e => {
        e.preventDefault()
        try {
            const form = new FormData(e.target)

            const serviciosSeleccionados = form.getAll('servicio-interes')

            if (serviciosSeleccionados.length === 0) {
                Alert({
                    tipo: 'info',
                    titulo: 'Selecciona al menos un servicio de interés.',
                    timer: 1500,
                })
                return
            }
            const serviciosUnidos = serviciosSeleccionados.join(', ')
            form.append('servicio_interes', serviciosUnidos)

            await addClienteService(form)

            // Limpiamos el formulario
            e.target.reset()
        } catch (error) {
            console.error('Error en onSubmitCliente => ', error.message)
        }
    }

    return (
        <div id="contacto-view" className="container my-5">
            <div className="seccion-contacto" data-aos="fade-up">
                {/* <img 
                    src={iconPeace} 
                    alt="peace" 
                    className="img-fluid victory-sign" 
                /> */}
                <span className="font-size-xl">🤜🏻🤛🏻</span>

                <h1 className="text-center fw-bold font-size-lg">
                    Trabajemos <span className="orange-color">juntos.</span>
                </h1>

                <p className="text-center dark-gray-color">Deja tus datos y te contactaremos:</p>

                <form onSubmit={onSubmitCliente}>
                    {/* FORMULARIO */}
                    <div className="row mb-4">
                        <div className="col-md-6 col-12">
                            <div className="mb-3">
                                <input
                                    type="text"
                                    className="form-control form-control--black"
                                    id="nombre"
                                    name="nombre"
                                    placeholder="Nombre *"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="mb-3">
                                <input
                                    type="text"
                                    className="form-control form-control--black"
                                    id="empresa"
                                    name="empresa"
                                    placeholder="Empresa *"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="mb-3">
                                <input
                                    type="email"
                                    className="form-control form-control--black"
                                    id="email"
                                    name="email"
                                    placeholder="E-mail *"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="mb-3">
                                <input
                                    type="tel"
                                    className="form-control form-control--black"
                                    id="telefono"
                                    name="telefono"
                                    placeholder="Teléfono *"
                                    autoComplete="off"
                                    maxLength={15}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <h5 className="text-center">Cuáles de nuestros servicios son de tu interés?</h5>

                    {/* OPCIONES PARA ELEGIR */}
                    <div className="checkbox-wrapper my-4">
                        <input
                            type="checkbox"
                            className="checkbox"
                            id="Sitio-Web"
                            name="servicio-interes"
                            value="Sitio Web"
                            defaultChecked
                        />
                        <label htmlFor="Sitio-Web">Sitio Web</label>

                        <input
                            type="checkbox"
                            className="checkbox"
                            id="E-commerce"
                            name="servicio-interes"
                            value="E-commerce"
                        />
                        <label htmlFor="E-commerce">E-commerce</label>

                        <input
                            type="checkbox"
                            className="checkbox"
                            id="Landing-Page"
                            name="servicio-interes"
                            value="Landing Page"
                        />
                        <label htmlFor="Landing-Page">Landing Page</label>

                        <input
                            type="checkbox"
                            className="checkbox"
                            id="Marketing-Digital"
                            name="servicio-interes"
                            value="Marketing Digital"
                        />
                        <label htmlFor="Marketing-Digital">Marketing Digital</label>

                        <input
                            type="checkbox"
                            className="checkbox"
                            id="Aplicacion-Movil"
                            name="servicio-interes"
                            value="Aplicación Móvil"
                        />
                        <label htmlFor="Aplicacion-Movil">Aplicación Móvil</label>
                    </div>

                    <button type="submit" className="btn btn-orange text-white rounded px-3 py-2">
                        Enviar
                    </button>
                </form>
            </div>
        </div>
    )
}
