import React from 'react';
import './Conocenos.css'
import image from '../../assets/images/multiple-projects.png'
import PreguntasFrecuentes from '../../components/PreguntasFrecuentes';

export default function Conocenos() {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    let preguntas = [{
        id_pregunta:1,
        pregunta: "¿Qué pasos debo seguir para obtener una cotización de un servicio?",
        respuesta: 'Presiona el botón de "Contacto" en la barra de navegación y llena el formulario con tus datos y los detalles de tu proyecto. Nos pondremos en contacto contigo lo antes posible para brindarte una cotización exacta'
    },
    {
        id_pregunta:2,
        pregunta: "¿Cuánto tiempo se necesita para crear un sitio web?",
        respuesta: "El tiempo de desarrollo de un sitio web puede variar, dependiendo de la complejidad y los requerimientos específicos de cada proyecto. Para un proyecto web básico tarda entre 1 a 3 meses, y los más complejos pueden llegar a ser más de un año."
    },
    {
        id_pregunta:3,
        pregunta: "¿Qué tecnologías utilizan para la creación de sitios web?",
        respuesta: "Utilizamos principalmente React.js, Node.js, MongoDB, Firebase, HTML, CSS, JavaScript, Bootstrap, PHP, MySQL, Flutter, entre otras. También el manejo de APIs y librerías de terceros, por lo que tu proyecto puede integrarse con cualquier servicio que necesites."
    },
    {
        id_pregunta:4,
        pregunta: "¿Cuánto cuesta la creación de un sitio web?",
        respuesta: "Un sitio web básico con base de datos te cuesta al menos $30,000 MXN, pero el costo puede variar dependiendo de la complejidad de tu proyecto. Para un proyecto como un e-commerce desde cero, el costo es de al menos $180,000 MXN. Contacta con nosotros para obtener una cotización exacta."
    }]


    return (
        <div className='container-fluid my-5'>
            {/* <section className="section-page-hero">
                <div className="parallax-content">
                    <h1 className="fw-bold font-size-xl secondary-font">
                        Efocados en crear 
                        <br />
                        <span className='orange-color'>soluciones</span> digitales
                    </h1>
                </div>
            </section> */}

            {/* <section className="section-page-hero">
                <div className="parallax-content">
                    <h1 className="fw-bold font-size-xl secondary-font">
                        Efocados en crear 
                        <br />
                        <span className='orange-color'>soluciones</span> digitales
                    </h1>
                </div>
            </section> */}

            <div style={{height: 70}} />

            <div 
                data-aos="fade-up"
                data-aos-duration="1000"
                className='container text-center text-white'
            >
                <h1 className="fw-bold font-size-xl secondary-font">
                    Efocados en crear 
                    <br />
                    <span className='orange-color'>soluciones</span> digitales
                </h1>
                
                <p className='my-5'>
                    Desde un inicio, nuestra visión ha sido llevar a las empresas a la era digital ofreciendo servicios integrales, eficientes y accesibles que impulsen la optimización y el crecimiento de tu negocio a través de nuestros servicios y valores.
                </p>

                <div className='d-flex justify-content-evenly align-items-start'>
                    <div className='cardExperiencias'>
                        <h5 className='secondary-font'>
                            Calidad
                        </h5>
                        <span>
                            🙌🏻
                        </span>
                    </div>
                    <div className='cardExperiencias'>
                        <h5 className='secondary-font'>
                            Compromiso
                        </h5>
                        <span>
                            ✍🏻
                        </span>
                    </div>
                    <div className='cardExperiencias'>
                        <h5 className='secondary-font'>
                            Soporte
                        </h5>
                        <span>
                            💪🏻
                        </span>
                    </div>
                </div>
            </div>

            <div style={{height: 150}} />

            <div className='container text-white my-5'>
                <div className="row my-5">
                    <div className="col-md-6 col-12"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                    >
                        <h1 className="fw-bold font-size-xl secondary-font">
                            Soluciones a la <span className='orange-color'>medida</span>
                        </h1>

                        <p className='my-5'>
                            Nos aseguramos de que las herramientas, tecnologías y plataformas que utilizamos para diseñar, construir y brindar nuestros servicios sean soluciones sostenibles y eficientes para nuestros clientes y sus objetivos.
                        </p>
                    </div>
                    <div className="col-md-6 col-12 d-flex justify-content-center align-items-center"
                        data-aos="zoom-in-left"
                        data-aos-duration="1000">
                        <img src={image} className='img-fluid' alt='soluciones a la medida'/>
                    </div>
                </div>

            </div>

            <div style={{height: 150}} />

            <div className="container-fluid text-white"
                data-aos="flip-left"
                data-aos-duration="1000"
            >
                <h1 className="fw-bold font-size-xl secondary-font mb-4 text-center">
                    Preguntas frecuentes
                </h1>
                
                <PreguntasFrecuentes 
                    preguntas={preguntas}
                />
            </div>


        </div>
    );
}
