import Swal from 'sweetalert2'

export function AlertQuestion({
    titulo,
    mensaje = null,
    confirmButtonText,
    onConfirm = () => {},
    onCancel = () => {},
}) {
    Swal.fire({
        title: titulo,
        text: mensaje,
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        showLoaderOnConfirm: true,
        preConfirm: () => {},
        cancelButtonText: 'No',
        allowOutsideClick: () => !Swal.isLoading(),
    }).then(result => {
        if (result.isConfirmed) 
            onConfirm()

        if (result.isDismissed)
            onCancel()
    })
}

export function HideAlert() {
    Swal.close()
}

export function AlertLoading({ message = 'Cargando...' }) {
    Swal.fire({
        title: message,
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading()
        },
    })
}

export function Alert({ tipo, titulo, mensaje, timer = 1000 }) {
    let data = {
        icon: tipo,
        title: titulo,
        showConfirmButton: false,
        timer: timer,
    }

    if (mensaje) data.text = mensaje

    Swal.fire(data)
}
