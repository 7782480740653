import { Alert, AlertLoading, HideAlert } from '../utils/alerts'
import addCliente from '../services/addCliente'

export default function useClientes() {
    const addClienteService = async values => {
        try {
            AlertLoading({ message: 'Subiendo información...' })

            let response = await addCliente({ values: values })
            if (!response.ok) {
                throw new Error('Error al agregar el cliente')
            }

            let data = await response.json()
            if (data.response === 'error') {
                throw new Error(data.message)
            }

            setTimeout(() => {
                HideAlert()

                Alert({
                    tipo: 'success',
                    titulo: 'Listo, nos pondremos en contacto en breve.',
                    timer: 2000,
                })
            }, 1000)

            return true
        } catch (error) {
            console.error('Error en addClienteService => ', error.message)
            HideAlert()
            Alert({
                tipo: 'info',
                titulo: error.message,
                timer: 1200,
            })
            throw new Error('Error al agregar el cliente, intente más tarde.')
        }
    }

    return {
        addClienteService,
    }
}
